<template>
  <div class="p4b__parent">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="container">
      <div class="p4b__toc-logo">
        <img
          :src="logoImg"
          alt="Logo"
        >
      </div>
      <div class="d-flex justify-content-between">
        <h4 class="payment-text">
          Total payment
        </h4>
        <h4 class="payment-text">
          ${{ totalPayable }}
        </h4>
      </div>
      <b-card v-if="showPayment">
        <b-card-text>
          <b-form @submit.prevent="submitFullForm">
            <validation-observer
              ref="paymentCardForm"
            >
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Name on card"
                    vid="cardName"
                    rules="required"
                  >
                    <b-form-group
                      label="Name on card*"
                      label-class="p4b__input-label"
                      label-for="h-card-name"
                    >
                      <b-form-input
                        id="h-card-name"
                        v-model="cardName"
                        class="mobile-input__value-adjust p4b__input-text"
                        placeholder="First name and last name"
                        :state="(errors.length > 0 || cardNameValidation) ? false : null"
                        name="cardName"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="cardNameValidation"
                        class="text-danger"
                      >
                        {{ cardNameError }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Card number"
                    vid="cardNumber"
                    rules="required"
                  >
                    <b-form-group
                      label="Card number*"
                      label-class="p4b__input-label"
                      label-for="h-card-no"
                    >
                      <cleave
                        id="cardNumber"
                        v-model="cardNumber"
                        class="form-control p4b__input-text"
                        :raw="false"
                        :options="options.creditCard"
                        :state="(errors.length > 0 || cardNumberValidation) ? false : null"
                        placeholder="0000 0000 0000 0000"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="cardNumberValidation"
                        class="text-danger"
                      >
                        {{ cardNumberError }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  col="6"
                  md="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Expiry Date"
                    vid="expiryDate"
                    rules="required"
                  >
                    <b-form-group
                      label="Expiry Date*"
                      label-for="h-expiry-date"
                      label-class="p4b__input-label"
                    >
                      <!-- <b-input-group class="p4a__flat-pickr-wrapper">
                        <flat-pickr
                          id="h-expiry-date"
                          v-model="expiryDate"
                          class="form-control flat-pickr-group mobile-input__value-adjust"
                          placeholder="mm/yy"
                          :config="flatPickrConfig"
                          @input="expiryDateValidation == true ? expiryDateValidation = false : null"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            icon="CalendarIcon"
                            class="cursor-pointer p4b__text-base-light"
                            data-toggle
                            size="18"
                          />
                        </b-input-group-append>
                      </b-input-group> -->
                      <cleave
                        id="expiryDate"
                        v-model="expiryDate"
                        class="form-control p4b__input-text"
                        :raw="false"
                        :options="options.date"
                        :state="(errors.length > 0 || expiryDateValidation) ? false : null"
                        placeholder="mm/yyyy"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="expiryDateValidation"
                        class="text-danger"
                      >
                        {{ expiryDateError }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  col="6"
                  md="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cvv"
                    vid="cvv"
                    rules="required"
                  >
                    <b-form-group
                      label="CVV*"
                      label-class="p4b__input-label"
                      label-for="h-cvv"
                    >
                      <cleave
                        id="expiryDate"
                        v-model="cvv"
                        class="form-control p4b__input-text"
                        :raw="false"
                        :options="options.block"
                        :state="(errors.length > 0 || cvvValidation) ? false : null"
                        placeholder="3 digits"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="cvvValidation"
                        class="text-danger"
                      >
                        {{ cvvError }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-button
                class="p4b__payment-btn-common"
                block
                :disabled="isButtonDisabled"
                @click="submitFullForm"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="14"
                  style="vertical-align: middle;"
                />
                Confirm and pay
              </b-button>
            </validation-observer>
          </b-form>
        </b-card-text>
      </b-card>
    </div>

    <b-modal
      id="modal-payment-success"
      ref="payment-success-modal"
      modal-class="p4b__payment-status__modal"
      body-class="reduce-padding-0"
      size="470"
      centered
      no-stacking
      no-close-on-backdrop
      hide-footer
      header-class="front-modal"
    >
      <div class="p4b__payment-modal-icon">
        <b-avatar
          class="p4b__payment-modal-avatar-success"
          size="83.33px"
        >
          <span class="d-flex align-items-center">
            <feather-icon
              icon="CheckIcon"
              size="50"
            />
          </span>
        </b-avatar>
      </div>
      <div
        v-if="receipt && receipt.trxNo"
        class="p4b__payment-modal-content"
      >
        <h3>Payment successful</h3>
        <p>Thank you for the payment. Your receipt will be sent to your email <strong>{{ email }}.</strong></p>
        <b-button class="p4b__payment-btn-common">
          <b-img
            :src="noteIcon"
            alt="note-icon"
          />
          View receipt
        </b-button>
        <div class="p4b__modal-success-copy-text">
          <p>Receipt number: <strong>{{ receipt.trxNo }}</strong></p>
          <feather-icon
            icon="CopyIcon"
            height="22"
            width="19"
            cursor="pointer"
            color="#D6C691"
            @click="copyToClipboard(receipt.trxNo)"
          />
        </div>
      </div>
      <div
        v-else
        class="p4b__payment-modal-content"
      >
        <h3>Payment successful</h3>
        <p>Thank you for the payment. Your receipt will be sent to your email <strong>{{ email }}.</strong></p>
      </div>
      <div class="clear" />
    </b-modal>

    <b-modal
      id="modal-payment-failure"
      ref="payment-failure-modal"
      modal-class="p4b__payment-status__modal"
      body-class="reduce-padding-0"
      size="470"
      centered
      no-stacking
      no-close-on-backdrop
      hide-footer
      header-class="front-modal"
    >
      <div class="p4b__payment-modal-icon">
        <b-avatar
          class="p4b__payment-modal-avatar-danger"
          size="83.33px"
        >
          <span class="d-flex align-items-center">
            <feather-icon
              icon="XIcon"
              size="50"
            />
          </span>
        </b-avatar>
      </div>
      <div class="p4b__payment-modal-content">
        <h3>Payment failed</h3>
        <p>Payment was unsuccessful. Your credit card was not charged. Please select other payment mode or try again.</p>
        <b-button
          class="p4b__payment-btn-common"
          :to="{ name: 'file-payment-frontend-preview', params: { token: $route.params.token } }"
        >
          Try again
        </b-button>
        <b-button
          class="p4b__payment-modal-link-btn"
          variant="link"
          :to="{ name: 'file-payment-frontend-preview', params: { token: $route.params.token } }"
        >
          Back to review payment
        </b-button>
      </div>
      <div class="clear" />
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BCard, BCardText, BRow, BCol, BForm, BFormGroup, BFormInput, BAvatar, BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loader from '../Loader.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    // flatPickr,
    BForm,
    BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    BFormInput,
    BRow,
    BCol,
    BAvatar,
    BImg,

    Loader,
    Cleave,

    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      noteIcon: require('@/assets/images/frontend/p4b/icons/note.png'),
      // eslint-disable-next-line global-require
      logoImg: require('@/assets/images/frontend/p4b/logo-yellow.png'),

      cardName: '',
      cardNameValidation: false,
      cardNameError: 'Valid card name is required',
      cardNumber: '',
      cardNumberValidation: false,
      cardNumberError: 'Valid card no is required',
      expiryDate: '',
      expiryDateValidation: false,
      expiryDateError: 'Valid expiry date is required',
      cvv: '',
      cvvValidation: false,
      cvvError: 'Valid CVV date is required',
      totalPayable: 0,
      selectedOtherFees: [],
      selectedInstallments: [],
      selectedInvoices: [],
      paymentMethod: 'Card',
      fileDetail: {},
      tokenDetail: {},
      showPayment: false,
      isButtonDisabled: false,
      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['m', 'Y'],
        },
        block: {
          blocks: [3],
          numericOnly: true,
        },
      },
      receipt: {},
      email: '',
      omiseToken: '',

      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'm/y', minDate: 'today', disableMobile: true,
      },
      required,
    }
  },

  beforeMount() {
    document.body.classList.add('p4b__payment-bg')
    const { token } = this.$route.params
    if (token) {
      this.$http.get('front-end/payment/validate/token', { params: { token } })
        .then(response => {
          if (response.data.success) {
            if (response.data.validToken) {
              this.showPayment = true
              this.omiseToken = response.data.omiseToken
              const paymentSelectionDetail = JSON.parse(localStorage.getItem('paymentSelectionDetail'))
              this.totalPayable = paymentSelectionDetail.totalPayable || 0
              this.selectedOtherFees = paymentSelectionDetail.selectedOtherFees || []
              this.selectedInstallments = paymentSelectionDetail.selectedInstallments || []
              this.selectedInvoices = paymentSelectionDetail.selectedInvoices || []
              this.fileDetail = paymentSelectionDetail.fileDetail || {}
              this.tokenDetail = paymentSelectionDetail.tokenDetail || {}
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },

  methods: {
    submitFullForm() {
      this.$refs.paymentCardForm.validate().then(success => {
        this.expiryDateValidation = false
        this.cvvValidation = false
        this.cardNumberValidation = false
        let validCardNumber = true
        let validCvv = true
        let validExpiryDate = true
        const trimmedCardNumber = this.cardNumber.trim().replace(/\s/g, '')
        if (trimmedCardNumber.length !== 16) {
          validCardNumber = false
          this.cardNumberError = 'Invalid card number'
          this.cardNumberValidation = true
        }
        const splittedExpiryDate = this.expiryDate.split('/')
        if (splittedExpiryDate.length !== 2) {
          validExpiryDate = false
          this.expiryDateError = 'Invalid expiry date'
          this.expiryDateValidation = true
        }
        if (splittedExpiryDate.length === 2) {
          if (splittedExpiryDate[0].length !== 2 || splittedExpiryDate[1].length !== 4) {
            validExpiryDate = false
            this.expiryDateError = 'Invalid expiry date'
            this.expiryDateValidation = true
          }
        }
        if (this.cvv.length !== 3) {
          validCvv = false
          this.cvvError = 'Invalid CVV'
          this.cvvValidation = true
        }
        if (success && validCardNumber && validCvv && validExpiryDate) {
          this.isButtonDisabled = true
          const tokenParameters = {
            expiration_month: Number(splittedExpiryDate[0]),
            expiration_year: Number(splittedExpiryDate[1]),
            name: this.cardName,
            number: Number(trimmedCardNumber),
            security_code: Number(this.cvv),
          }

          // console.log(tokenParameters)
          // eslint-disable-next-line no-undef
          Omise.setPublicKey(this.omiseToken)
          // eslint-disable-next-line no-undef
          Omise.createToken('card',
            tokenParameters,
            (statusCode, response) => {
              if (statusCode === 200) {
                const formData = new FormData()
                formData.append('url', window.location.origin)
                formData.append('token', this.$route.params.token)
                formData.append('paymentMethod', this.paymentMethod)
                formData.append('cardToken', response.id)
                formData.append('cardNumber', trimmedCardNumber.slice(-4))
                formData.append('totalAmount', this.totalPayable)
                formData.append('fileID', this.fileDetail.fileID)
                formData.append('paidInstallments', JSON.stringify(this.selectedInstallments))
                formData.append('paidInvoices', JSON.stringify(this.selectedInvoices))
                formData.append('reactivationFees', JSON.stringify(this.selectedOtherFees))

                this.$http.post('front-end/payment/frontend/card/charge', formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
                })
                  .then(async res => {
                    if (res.data.success) {
                      this.email = res.data.email
                      this.receipt = res.data.receipt || {}
                      await this.$nextTick()
                      this.$root.$emit('bv::show::modal', 'modal-payment-success', '')
                    } else {
                      this.$root.$emit('bv::show::modal', 'modal-payment-failure', '')
                    }
                  })
                  .catch(error => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
        }
      })
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Receipt number has been copied',
            icon: 'BellIcon',
          },
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      }
    },
  },
}
</script>
